<template>
    <router-view />
</template>

<script>
import { reactive, watch } from '@vue/composition-api';

import i18n from '@/libs/i18n/index';

import useRoles from '@/views/roles/useRoles';
import useStrategies from '@/views/strategies/useStrategies';
import useSubscriptions from '@/views/subscriptions/useSubscriptions';

export default {
    setup() {
        const { fetchRoles } = useRoles();
        const { fetchStrategies } = useStrategies();
        const { fetchSubscriptions } = useSubscriptions();

        const i18nReactive = reactive(i18n);

        const fetchData = () => {
            fetchRoles();
            fetchStrategies();
            fetchSubscriptions();
        };

        fetchData();

        watch(
            () => i18nReactive.locale,
            () => {
                fetchData();
            }
        );
    },
};
</script>

<style lang="scss" scoped></style>
