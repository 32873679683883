/* eslint-disable no-restricted-syntax */
import store from '@/store';
import { ref, reactive } from '@vue/composition-api';
import i18n from '@/libs/i18n/index';

import useHandleError from '@/utils/useHandleError';

export default function useSubscriptions() {
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);
    const locale = ref(i18nReactive.locale);

    const fetchSubscriptions = () => {
        return store
            .dispatch('app-subscriptions/fetchSubscriptions', {
                locale: locale.value,
            })
            .catch(showErrorMessage);
    };

    const getSubscriptions = () => {
        return store.getters['app-subscriptions/GET_SUBSCRIPTIONS'];
    };

    const getSubscriptionByID = (id) => {
        const subscriptions = getSubscriptions();
        // eslint-disable-next-line guard-for-in
        for (const subscription of subscriptions) {
            const matchedSubscription = subscription.find(
                (item) => item.id === id
            );

            if (matchedSubscription) return matchedSubscription;
        }

        return {};
    };

    return {
        // Methods
        fetchSubscriptions,
        getSubscriptions,
        getSubscriptionByID,
    };
}
