/* eslint-disable no-restricted-syntax */
import store from '@/store';
import { ref, reactive } from '@vue/composition-api';
import i18n from '@/libs/i18n/index';

import useHandleError from '@/utils/useHandleError';

export default function useRoles() {
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);
    const locale = ref(i18nReactive.locale);

    const fetchRoles = () => {
        return store
            .dispatch('app-roles/fetchRoles', { locale: locale.value })
            .catch(showErrorMessage);
    };

    const getRoles = () => {
        return store.getters['app-roles/GET_ROLES'];
    };

    const getRoleByID = (id) => {
        const roles = getRoles();
        // eslint-disable-next-line guard-for-in
        for (const role of roles) {
            const matchedWeapon = role.find((item) => item.id === id);

            if (matchedWeapon) return matchedWeapon;
        }

        return {};
    };

    return {
        // Methods
        fetchRoles,
        getRoles,
        getRoleByID,
    };
}
